import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import Card from "~components/card/card"

/* Import Local Style(s) */
import "./log-in-link.scss"

const LogInLink = () => (
  <Link to="/vip" className="log-in">
    <Card backgroundColor="var(--color-lightest-gray)">
      <p>VIP Login</p>
    </Card>
  </Link>
)

export default LogInLink
